import React, { Component } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import _ from "lodash"
import Img from "gatsby-image"
import "./news.scss"
import filter from "lodash/fp/filter"
import sortBy from "lodash/fp/sortBy"
import reverse from "lodash/fp/reverse"
import flow from "lodash/fp/flow"

const Multimedia = props => (
  <StaticQuery
    query={graphql`
      query Multimedia {
        allNewsJson: allStrapiMultimedia {
          edges {
            node {
              image {
                localFile{
                  childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 210) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              locale
              dateShort
              title
              subtitle
              description
              link
            }
          }
        }
      }
    `}
    render={data => {
      return <NewsContent data={data} {...props} />
    }}
  />
)


class NewsContent extends Component {
  constructor(props) {
    super(props)
    this.state = { filteredData: [], counter: 1, slicedData: [] }
  }

  componentDidMount() {
    this.loadItems()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.counter !== this.state.counter) {
      this.loadItems()
    }
  }

  loadItems() {
    const data = this.props.data.allNewsJson.edges

    const filteredData = flow(
      filter(
        d =>
          d.node &&
          d.node.locale === this.props.locale &&
          d.node
      ),
      sortBy(d => new Date(d.node.dateShort)),
      reverse
    )(data)

    const slicedData = _.slice(filteredData, 0, this.state.counter * 3)

    this.setState({
      filteredData,
      slicedData,
    })
  }

  render() {
    const news = this.state.slicedData
    const remainingItems =
      this.state.filteredData.length - this.state.slicedData.length

    return (
      <>
        <div className="row">
          {news.map((n, i) => {
            return (
              <div
                className="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                key={i}
                style={{ marginBottom: 60 }}>
                <div
                  style={{
                    width: "100%",
                    overflow: "hidden",
                  }}>
                  {" "}
                  {n.node.image?.localFile?.childImageSharp?.fluid ? 
                    <Img fluid={n.node.image?.localFile?.childImageSharp?.fluid} /> : 
                    <div style={{background: '#EFEEEF', paddingBottom: '70.6667%'}}></div>
                  }
                </div>
                <div
                  style={{ marginTop: 30, marginBottom: 30, height: 100 }}
                  className="text-default bold">
                  {n.node?.link ? <a
                    className="text-default bold title--news"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={n.node.link}>
                    {n.node.title}
                  </a>: 
                  <div className="text-default bold">{n.node.title}</div>}
                </div>
                <div
                  style={{ height: 80 }}
                  className="text-default news-description">
                  {n.node.description}
                </div>
                <div style={{ marginTop: 30 }} className="text-default">
                  {" "}
                  {n.node.subtitle}
                </div>
                <div
                  className="divider"
                  style={{ height: 3, marginTop: 30, marginBottom: 20 }}
                />
              </div>
            )
          })}
        </div>
        <div className="row">
          <div
            className="col-sm-12"
            style={{
              textAlign: "center",
              marginBottom: this.props.marginBottom,
            }}>
            {remainingItems !== 0 && (
              <div
                onClick={() =>
                  this.setState({ counter: this.state.counter + 1 })
                }
                className="btn btn-creialab text--legend"
                style={{
                  color: "#ffffff",
                  minWidth: 146,
                  height: 38,
                  lineHeight: "25px",
                  cursor: "pointer",
                }}>
                {this.props.locale === "pt"
                  ? "Ver mais " + "(" + remainingItems + ")"
                  : "See more " + "(" + remainingItems + ")"}
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}
export default Multimedia
