import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Banner from "../components/Banner/banner2"
import News from "../components/News/news"
import Multimedia from "../components/Multimedia/multimedia"
import LocalizedLink from "../components/utils/LocalizedLink"

export const query = graphql`
  query Homapage($locale: String!) {
    homepage: strapiHomepage(locale: { eq: $locale }) {
      introText
      isVisibleInListView
      multimediaTitle
      newsTitle
      seeMoreButton
      Banner {
        bannerTitle
        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 294) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default ({ pageContext: { locale, currentPath }, data }) => {
  const content = data.homepage

  return (
    <Layout locale={locale} currentPath={currentPath}>
      <Banner
        banner={content.Banner.background.localFile}
        text={content.Banner.bannerTitle}
        layer={true}
        opacityValue={0.1}
        //logo={obcrei}
      />
      <div className="container">
        <div
          className="row justify-content-md-center"
          style={{ marginTop: 100, marginBottom: 140 }}>
          <div className="col-sm-10 col-xs-12 text-default color-primary">
            {content.introText}
          </div>

          <div
            className="col-sm-10 col-xs-12 text-default color-primary"
            style={{ marginTop: 30 }}>
            <LocalizedLink
              to="/sobre"
              locale={locale}
              className="btn btn-creialab text--legend"
              style={{
                color: "#ffffff",
                minWidth: 146,
                height: 38,
                lineHeight: "25px",
              }}>
              {content.seeMoreButton}
            </LocalizedLink>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-sm-12 col-xs-12 text-left ">
            <h2>{content.newsTitle}</h2>
          </div>
        </div>
        <News locale={locale} contentType={"news"} />
        <div
          className="row justify-content-md-center"
          style={{ marginBottom: 40, marginTop: 40 }}>
          <div className="col-sm-12 col-xs-12 text-left ">
            <h2>{content.multimediaTitle}</h2>
          </div>
        </div>
        <Multimedia
          locale={locale}
          contentType={"multimedia"}
          marginBottom={"100px"}
        />
      </div>
    </Layout>
  )
}

// export const index = graphql`
//   query($locale: String) {
//     file(
//       name: { eq: $locale }
//       relativeDirectory: { eq: "textContent/index" }
//     ) {
//       childIndexJson {
//         bannerImage {
//           childImageSharp {
//             fluid(maxWidth: 1200, maxHeight: 294) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//         bannerText
//         introText
//         seeMoreButton
//         newsTitle
//         multimediaTitle
//       }
//     }
//   }
// `
